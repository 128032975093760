import { ClockIcon } from '@heroicons/react/24/outline';
import { Link } from '@tanstack/react-router';

import { Button } from './ui/button';
import { Tooltip, TooltipContent, TooltipTrigger } from './ui/tooltip';

export const TimeTrackerButton = () => {
  return (
    <Tooltip>
      <TooltipTrigger asChild>
        <Link to="/">
          <Button variant="ghost" size="icon" className="-mr-1.5 p-1.5 sm:mr-1">
            <ClockIcon />
          </Button>
        </Link>
      </TooltipTrigger>
      <TooltipContent>
        <p>View Time Tracker</p>
      </TooltipContent>
    </Tooltip>
  );
};
