import { ReportType } from '@/types/ReportType';

import { BetaBar } from '../BetaBar';
import { TimeTrackerButton } from '../TimeTrackerButton';
import { BillingHistoryReport } from './BillingHistoryReport';
import { ReportTypeDropdownMenu } from './ReportTypeDropdownMenu';

export const Reports = () => {
  const searchParams = new URLSearchParams(window.location.search);
  const currentReportType = (searchParams.get('reportType') ??
    'Select Report Type...') as ReportType;

  return (
    <>
      <BetaBar />
      <header className="sticky top-0 z-50 w-full bg-navigation text-navigation-foreground backdrop-blur supports-[backdrop-filter]:bg-navigation-transparent/75">
        <div className="bg-navigation/95 p-2.5">
          <h1 className="mb-1 scroll-m-20 text-center text-xl font-medium tracking-tight sm:text-4xl sm:font-normal">
            Reports
          </h1>
          <div className="absolute right-4 top-2 flex sm:top-3">
            <div className="flex items-center sm:pr-3">
              <TimeTrackerButton />
            </div>
          </div>
        </div>
        <ReportTypeDropdownMenu currentReportType={currentReportType} />
      </header>
      {searchParams.get('reportType') === 'Billing History Report' && <BillingHistoryReport />}
    </>
  );
};
